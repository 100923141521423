<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">历思入选环境保护部环境损害鉴定推荐机构名录</div>
      <p class="time">2016.12.09</p>
      <p class="space_content">
        在2016年环境保护部办公厅环办政法[2016]10号文件关于印发《环境损害鉴定评估推荐机构名录（第二批）》的通知中，福建历思司法鉴定所联合福建省环境科学研究院进入环保部环境损害鉴定推荐机构名录。</p>

      <p class="space_content titleText">环保部相关文件链接：</p>
      <p class="space_content" style="word-wrap:break-word">
        <a style="text-align: left; word-break: break-all;
        word-wrap: break-word;
"
           href="http://www.mee.gov.cn/gkml/hbb/bgt/201602/t20160205_329939.htm">
          http://www.mee.gov.cn/gkml/hbb/bgt/201602/t20160205_329939.htm
        </a>
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/4'"><p class="ellipsis1">
            历思承办案件入选福建省高级人民法院2015年十大典型案件</p></router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/6'"><p class="ellipsis1">秦天宝等：我国土壤污染防治立法探究</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
